import { LinkList } from '@/design-system/molecules/LinkList';
import { generateStyleModifiers } from '@/utils/generateStyleModifiers';

const IntegrationLinkList = (props) => {
    const modifiers = generateStyleModifiers(props.appliedCssClassNames);
    const theme = modifiers.theme;

    return <LinkList {...props} theme={theme} />;
};

export default IntegrationLinkList;
