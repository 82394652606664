import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { LazyIconArrowRightCircle } from '@/design-system/atoms/Icons/IconArrowRightCircle/Lazy';
import { Text } from '@/design-system/atoms/Text';
import { trackInteraction } from '@/utils/analytics';

import styles from './LinkListItem.module.scss';

const LinkListItem = ({
    id,
    linkWrapper,
    className,
    classNameWrapper,
    label,
    ariaLabel,
    href,
    csr,
    target,
    description,
    titleTruncation,
    descriptionTruncation,
    analytics,
    coveoAnalyticsCallbacks,
    ...props
}) => {
    const WrapperElement = linkWrapper ? linkWrapper : 'div';
    const LinkTag = csr ? Link : 'a';
    const handleClickEvent = React.useCallback(
        (event) => {
            trackInteraction({
                componentName: 'Link List',
                actionLabel: label,
                linkHref: href,
                interactionType: 'cta',
                selector: 'a',
                ...analytics,
            });
            coveoAnalyticsCallbacks?.onClick?.();
        },
        [analytics, href, label, coveoAnalyticsCallbacks],
    );

    const handleContextMenu = useCallback(() => {
        coveoAnalyticsCallbacks?.onContextMenu?.();
    }, [coveoAnalyticsCallbacks]);

    const handleMouseDown = useCallback(() => {
        coveoAnalyticsCallbacks?.onMouseDown?.();
    }, [coveoAnalyticsCallbacks]);

    const handleMouseUp = useCallback(() => {
        coveoAnalyticsCallbacks?.onMouseUp?.();
    }, [coveoAnalyticsCallbacks]);

    const handleTouchStart = useCallback(() => {
        coveoAnalyticsCallbacks?.onTouchStart?.();
    }, [coveoAnalyticsCallbacks]);

    const handleTouchEnd = useCallback(() => {
        coveoAnalyticsCallbacks?.onTouchEnd?.();
    }, [coveoAnalyticsCallbacks]);

    useEffect(() => {
        coveoAnalyticsCallbacks?.cancelPendingSelect?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WrapperElement className={classnames(styles[`link-list-item`], classNameWrapper)}>
            {href ? (
                <LinkTag
                    id={id}
                    className={classnames(styles[`link-list-item__link`], className)}
                    href={href}
                    target={target}
                    to={csr ? href : undefined}
                    aria-label={ariaLabel}
                    {...props}
                    onContextMenu={coveoAnalyticsCallbacks ? handleContextMenu : undefined}
                    onMouseDown={coveoAnalyticsCallbacks ? handleMouseDown : undefined}
                    onMouseUp={coveoAnalyticsCallbacks ? handleMouseUp : undefined}
                    onTouchEnd={coveoAnalyticsCallbacks ? handleTouchEnd : undefined}
                    onTouchStart={coveoAnalyticsCallbacks ? handleTouchStart : undefined}
                    onClick={analytics ? handleClickEvent : undefined}
                    data-trigger={analytics ? analytics?.selector || 'a' : undefined}
                >
                    <Text
                        tag="span"
                        variant={Text.VARIANT.CTA1}
                        content={label}
                        className={styles[`link-list-item__text`]}
                        lineClamp={titleTruncation ? titleTruncation : null}
                    />
                    <LazyIconArrowRightCircle
                        className={styles[`link-list-item__icon`]}
                        width={20}
                        height={20}
                    />
                </LinkTag>
            ) : (
                <p
                    id={id}
                    className={classnames(
                        styles[`link-list-item__link`],
                        styles[`link-list-item__link--text`],
                        className,
                    )}
                >
                    <Text
                        tag="span"
                        variant={Text.VARIANT.CTA1}
                        content={label}
                        className={styles[`link-list-item__text`]}
                        lineClamp={titleTruncation ? titleTruncation : null}
                    />
                </p>
            )}

            {
                <Text
                    tag="p"
                    variant={Text.VARIANT.T2}
                    className={styles[`link-list-item__description`]}
                    content={description}
                    lineClamp={descriptionTruncation ? descriptionTruncation : null}
                />
            }
        </WrapperElement>
    );
};

LinkListItem.propTypes = {
    /**
     * Option unique ID for the link list item
     */
    id: PropTypes.string.isRequired,
    /**
     * Option wrapper element for the link list item
     */
    linkWrapper: PropTypes.elementType,
    /**
     * Option additional class name for the link list item
     */
    className: PropTypes.string,
    /**
     * Required label for the link list item
     */
    label: PropTypes.string.isRequired,
    /**
     * Optional aria-label for the link list item
     */
    ariaLabel: PropTypes.string,
    /**
     * Optinal href for the link list item
     */
    href: PropTypes.string,
    /**
     * Optional target for the link list item
     */
    target: PropTypes.string,
    /**
     * Optional description for the link list item
     */
    description: PropTypes.string,
    /**
     * Optional title truncation for the link list item
     */
    titleTruncation: PropTypes.shape({ sm: PropTypes.number, lg: PropTypes.number }),
    /**
     * Optional description truncation for the link list item
     */
    descriptionTruncation: PropTypes.shape({ sm: PropTypes.number, lg: PropTypes.number }),
};

export default LinkListItem;
